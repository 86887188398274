.personal__info {
	width: 100%;
	padding: 30px;
	border: 1px solid #f2f2f2;
	border-radius: var(--border-radius-default);
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);

	@media (min-width: 768px) {
		display: grid;
		column-gap: 24px;
		row-gap: 28px;
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		gap: 28px;
	}
}

.formElm {
	display: flex;
	flex-direction: column;
	gap: 18px;

	&.elm_driverName {
		@media (min-width: 768px) {
			grid-column: span 2;
		}
	}

	label {
		display: block;
		font-size: 18px;
		font-weight: 500;
		color: #282828;
	}

	input,
	select {
		font-size: 18px;
		color: #000;
		padding: 27px 25px 23px;
		border: 1px solid #bdbdbd;
		border-radius: var(--border-radius-default);
		background-color: #fff;
		transition: all .3s ease;

		&::placeholder {
			font-size: 18px;
			color: #828282;
		}
	}

	.select__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;

		&:before {
			display: inline-block;
			width: 16px;
			height: 11px;
			content: "";
			mask: url('/assets/icons/caren/icon-select-arrow.svg');
			mask-size: cover;
			background-color: #2bb673;

			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			right: 23px;
		}

		select {
			appearance: none;
		}
	}
}
