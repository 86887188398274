@import url(mailchimp.css) layer(theme);

@layer theme {
	footer {
		font-size: 18px;
		color: #fff;
		padding-block: 50px;
		margin-top: auto;
		--container-width: 1920px;
		background-color: var(--color-primary);

		> .contentWrap {
			display: flex;
			flex-direction: column;
		}

		.footer__row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			gap: 20px;

			&.above {
				padding-bottom: 36px;
				margin-bottom: 36px;
				border-bottom: 2px solid #fff;
			}

			&.below {
				.column {
					&.europcar {
						display: flex;
						flex-direction: column;
						gap: 10px;

						img {
							width: 200px;
							height: auto;
						}
					}

					&.mailinglist {
						display: flex;
						flex-direction: column;
						gap: 10px;

						@media screen and (max-width: 480px) {
							width: 100%;
							margin-top: 10px;
						}
					}
				}
			}
		}

		.contact {
			display: flex;
			gap: 10px;
			flex-wrap: wrap;

			a {
				position: relative;
				color: #fff;

				&:after {
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 0;
					height: 1px;
					content: "";
					display: block;
					background-color: #fff;
					transition: width .3s ease 0s, left .3s ease 0s;
				}

				&:hover {
					&:after {
						left: 0;
						width: 100%;
					}
				}
			}
		}

		.social {
			display: flex;
			gap: 20px;

			@media screen and (max-width: 480px) {
				margin: 0 auto;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				aspect-ratio: 1;
				border: 1px solid #fff;
				border-radius: 50%;
				transition: border-width .1s ease;

				&:before {
					position: absolute;
					content: '';
					width: 21px;
					height: 21px;
					background-color: #fff;
					mask-repeat: no-repeat;
				}

				&.instagram:before {
					mask: url('/assets/icons/social-icons/instagram.svg');
				}

				&.facebook:before {
					width: 11px;
					mask: url('/assets/icons/social-icons/facebook.svg');
				}

				&.twitter:before {
					mask: url('/assets/icons/social-icons/twitter.svg');
				}

				&:hover {
					border-width: 2px;
				}
			}
		}
	}
}
