.icon-button {
	padding: 3px;
	font-size: 0;
	background-color: transparent;
}

.blog__container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
	grid-gap: 30px;
}

.error__page {
	display: flex;
	justify-content: center;
	align-items: center;


	.error__page__inner {
		align-items: center;
		line-height: 20px;
		text-align: center;
		display: flex;
		flex-direction: column;
		min-height: 500px;
		width: 540px;
		justify-content: center;

		.error__page-h1 {
			font-size: 90px;
			margin-bottom: 30px;
		}

		.error__page-strong {
			font-size: 22px;
		}


	}

	.thanks__page__info {
		line-height: 32px;

		.thanks__page-h1 {
			font-size: 50px;
			font-weight: normal;
			margin-top: 40px;
			margin-bottom: 15px;
		}

		.thanks__page-h2 {
			font-weight: normal;
			font-size: 30px;
		}

		p {
			font-size: 20px;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		a {
			text-decoration: underline;
		}

		@media (max-width: 768px) {
			.thanks__page-h1 {
				font-size: 30px;
			}

			.thanks__page-h2 {
				font-size: 20px;
			}

			p {
				font-size: 18px;
			}

		}
	}


}

