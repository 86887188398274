@layer components {
	.block_latestBlog {
		background-image: linear-gradient(180deg, hsla(24, 6%, 84%, .5), hsla(24, 6%, 84%, 0));
		--block-padding: 50px;
	}

	.latest__blog {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
