@font-face {
	font-family: "flama";
	src: url("/fonts/flama-basic.woff2") format("woff2"),
	url("/fonts/flama-basic.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "flama";
	src: url("/fonts/flama-bold.woff2") format("woff2"),
	url("/fonts/flama-bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "flama";
	src: url("/fonts/flama-medium.woff2") format("woff2"),
	url("/fonts/flama-medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

:root {
	--screen-xs: 430px;
	--screen-smL: 640px;
	--screen-md: 768px;
	--screen-lg: 1024px;
	--screen-xl: 1280px;
	--screen-2xl: 1728px;
	--screen-super: 1921px;

	--main-max-width: 1475px;
	--body-font-family: "flama";
	--body-font-size-lg: 20px;
	--body-font-size-sm: 16px;
	--body-line-height: normal;
	--body-background-color: #fef9f3;
	--heading-font-family: "flama";
	--heading-line-height: normal;
	--heading-font-weight: 700;
	--color-primary: #5a4a42;
	--color-secondary: #e69d2e;
	--border-radius-default: 6px;
}

html {
	background-color: var(--body-background-color);
	font-family: var(--body-font-family), sans-serif;
}

body {
	font-size: var(--body-font-size-sm);
	line-height: var(--body-line-height);
	color: var(--color-primary);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-family: var(--heading-font-family), sans-serif;
	font-weight: 700;
	line-height: var(--heading-line-height);
	color: var(--color-primary);
}

main > h1 {
	margin-block: 40px 30px;
	color: #333;

	.titleLine & {
		position: relative;
		color: var(--color-primary);
		text-transform: uppercase;

		@media (min-width: 1535px) {
			font-size: 40px;
		}

		&:before {
			content: '';
			position: absolute;
			left: -110px;
			top: 16px;
			width: 100px;
			height: 2px;
			background: var(--color-secondary);
		}
	}
}

a {
	color: inherit;
	text-decoration: none;
	transition: color .4s ease;

	&:active, :hover {
		outline: 0;
		color: var(--color-secondary);
	}

	&:hover, :focus {
		color: var(--color-secondary);
	}
}

img {
	max-width:100%;
	height:auto;
}

main {
	margin-block-start: 15px;
	margin-block-end: 40px;

	.home & {
		margin-block-start: 0;
	}
}

main:not(:has(>.content-blocks)),
.contentWrap {
	width: 100%;
	max-width: var(--container-width, 1384px);
	padding-inline: var(--container-padding, 20px);
	margin-inline: auto;
}

.content-blocks {
	> .block {
		--container-padding: 230px;
		--container-width: 1920px;
		padding-block: var(--block-padding, 30px);

		@media (max-width: 1530px) {
			--container-padding: 130px;
		}
		@media (max-width: 1023px) {
			--container-padding: 100px;
		}
		@media (max-width: 767px) {
			--container-padding:20px;
		}

		&:first-child {
			padding-block-start: 0;
		}
		&:last-child {
			padding-block-end: 0;
		}
	}
}

.mb {
	margin-bottom: 40px;
}
