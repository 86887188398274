@layer components {
	.sidebarWrapper {
		position: relative;

		@media (min-width: 1024px) {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 2rem;
		}
		@media (max-width: 1023px) {
			display: flex;
			flex-direction: column-reverse;
		}

		&:before {
			content: '';
			position: fixed;
			inset: 0;
			mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath fill='%23fff' d='M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
			mask-repeat: no-repeat;
			mask-position: center center;
			mask-size: 150px;
			transition: opacity .4s ease;
			opacity: 0;
			pointer-events: none;
			background: var(--color-primary);
		}

		> div {
			transition: opacity .4s ease;
		}

		.loading & {
			&:before {
				opacity: 1;
			}
			> div {
				opacity: .5;
				pointer-events: none;
			}
		}

		.main {
			@media (min-width: 1024px) {
				grid-column: span 8;
			}

			h2.subTitle {
				font-size: 32px;
				margin-block: 60px 46px;
				color: #333;
			}
		}

		.sidebar {
			@media (min-width: 1024px) {
				grid-column: span 4;
				width: 100%;
				max-width: 400px;
				margin-left: auto;
			}
			@media (max-width: 1023px) {
				margin-bottom: 30px;
			}

			.caren__booking {
				border: 0;
			}
		}
	}


	main {
		> .caren__stepper {
			@media (min-width: 992px) {
				margin-top: 40px;
				margin-bottom: 60px;
			}
		}

		.caren__actionCards {
			display: grid;
			grid-gap: 25px;

			@media (min-width: 1023px) {
				grid-template-columns: repeat(2, 1fr);
			}

			&+.buttonWrapper {
				margin-top: 46px;
				--border-radius-default: 16px;

				button {
					width: 100%;
					text-transform: none;
					padding-block: 26px;
				}
			}
		}
	}

	.flash-messages {
		background: rgba(255, 255, 255, 0.90);
		box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.1);
		padding: 20px;
		border-radius: var(--border-radius-default);
		margin-bottom: 30px;
		font-weight: 500;

		.error {
			color: #DC4F00;
		}

		.home & {
			position: absolute;
			z-index: 99;
			margin: 0;
			padding: 15px 25px;
			animation-name: fadeOut;
			animation-fill-mode: both;
			animation-duration: .4s;
			animation-delay: 5s;
		}
	}
}
