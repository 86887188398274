.block_bookingBlock .contentWrap {
	--container-width: 1960px;
	--container-padding: 0;

	:first-child& {
		--container-width: none;
	}
}

.booking__block {
	text-align: center;

	@media (min-width: 920px) {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap:200px;
		width: 100%;
		max-width: 1920px;
		min-height: 600px;
		padding-top: 100px;
		padding-bottom: 70px;
		padding-inline: 40px;

		.block_bookingBlock:first-child & {
			min-height: 600px;
			height: 100vh;
			padding-top: 310px;
			padding-bottom: 80px;
			max-width: none;
		}
	}

	.block_bookingBlock:not(:first-child) & {
		@media (max-width: 1024px) and (min-width: 920px) {
			min-height: 500px;
			padding-top: 85px;
			padding-bottom: 60px;
		}
	}

	.wrapper {
		@media (max-width: 919px) {
			position: relative;
			min-height: 400px;
			padding: 40px;

			.block_bookingBlock:first-child & {
				height: 60vh;
				max-height: 600px;
				padding: 150px 40px 0;
			}
		}
		@media (max-width: 768px) {
			min-height: 350px;
		}
	}

	.background {
		position: absolute;
		inset: 0;
		pointer-events: none;
		z-index: -1;

		img {
			position: absolute;
			inset: 0;
			object-fit: cover;
			object-position: bottom;
			width: 100%;
			height: 100%;
		}
	}

	h2 {
		font-size: 40px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: normal;

		.whiteText& {
			color: #fff;
		}

		@media (max-width: 768px) {
			font-size: 30px;
		}
	}

	.caren__booking {
		@media (max-width: 919px) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}

div.flatpickr-calendar {
	font-size: 13px;

	.flatpickr-months {
		padding: 10px 10px 0;
	}
	.flatpickr-prev-month,
	.flatpickr-next-month {
		top: 6px;
	}
	.flatpickr-current-month {
		font-size: 15px;
	}
	span.flatpickr-weekday {
		font-size: 12px;
		font-weight: normal;
	}

	.flatpickr-day.selected,
	.flatpickr-day.startRange,
	.flatpickr-day.endRange,
	.flatpickr-day.selected.inRange,
	.flatpickr-day.startRange.inRange,
	.flatpickr-day.endRange.inRange,
	.flatpickr-day.selected:focus,
	.flatpickr-day.startRange:focus,
	.flatpickr-day.endRange:focus,
	.flatpickr-day.selected:hover,
	.flatpickr-day.startRange:hover,
	.flatpickr-day.endRange:hover,
	.flatpickr-day.selected.prevMonthDay,
	.flatpickr-day.startRange.prevMonthDay,
	.flatpickr-day.endRange.prevMonthDay,
	.flatpickr-day.selected.nextMonthDay,
	.flatpickr-day.startRange.nextMonthDay,
	.flatpickr-day.endRange.nextMonthDay {
		background:  #e69d2e;
		border-color: #e69d2e;
	}

	.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
	.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
	.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
		box-shadow: -10px 0 0 #e6e6e6;
	}

	.dayContainer {
		width: 100%;
		min-width: 300px;
		max-width: none;
	}
}
