.action__card {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 100%;
	padding: 30px;
	border: 1px solid #f2f2f2;
	border-radius: var(--border-radius-default);
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
	transition: all .4s ease;

	&:hover {
		border-color: var(--color-primary);
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	}

	@media (max-width: 768px) {
		padding: 20px;
	}

	.iconWrapper {
		display: flex;
		align-items: center;
		gap: 30px;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 90px;
			height: 90px;
			border-radius: 50%;
			background-color: rgba(178, 104, 28, 0.10);

			img {
				width: 100%;
				max-width: 53px;
				height: auto;
			}
		}

		.title__price {
			display: flex;
			flex-direction: column;
			gap: 10px;
			flex: 1;

			label {
				font-size: 20px;
				font-weight: 500;

				&:before {
					content: '';
					position: absolute;
					inset: 0;
					z-index: 1;
					cursor: pointer;
				}
			}

			.price {
				color: var(--color-secondary);
				font-weight: 500;
			}
		}
	}

	.content {
		line-height: 1.5;

		p {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}

	.buttonWrapper {
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		> button {
			all: unset;
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 18px;
			padding: 9px 20px 8px;
			cursor: pointer;
			border-radius: var(--border-radius-default);
			border: 1px solid var(--color-secondary);
			transition: background-color .3s ease;

			&:before {
				display: inline-block;
				width: 24px;
				height: 25px;
				content: "";
				mask: url('/assets/icons/caren/icon-check-circle.svg');
				mask-size: cover;
				background-color: var(--color-secondary);
				transition: background-color .3s ease;
			}

			&:hover {
				background-color: var(--color-secondary);

				&:before {
					background-color: var(--color-primary);
				}
			}
		}

		input:checked + button {
			background-color: var(--color-secondary);

			&:before {
				mask: url('/assets/icons/caren/icon-check-circle-checked.svg');
				background-color: var(--color-primary);
			}

			&:after {
				content: attr(data-added);
			}
		}
		input:not(:checked) + button {
			&:after {
				content: attr(data-add);
			}
		}

		.amountWrapper {
			display: flex;

			button {
				all: unset;
				font-size: 26px;
				color: #fff;
				padding: 8px 15px 8px;
				cursor: pointer;
				border: 1px solid var(--color-secondary);
				background-color: var(--color-secondary);
				transition: all .3s ease;

				&.minus {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					border-right: none;
				}

				&.plus {
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
					border-left: none;
				}

				&:hover {
					color: var(--color-secondary);
					background-color: #fff;
				}
			}

			input[type=number] {
				all: unset;
				width: 46px;
				font-size: 18px;
				color: var(--color-secondary);
				text-align: center;
				border: 1px solid var(--color-secondary);

				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					appearance: none;
				}

				&:focus-visible {
					outline: 0;
				}
			}
		}
	}

	input {
		opacity: 0;
	}

	&:has(.amountWrapper) label:before {
		display: none;
	}
}
