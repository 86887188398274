@layer components {
	.image__gallery {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;

		.image__gallery__inner {
			max-width: 1060px;

			h2 {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				font-size: 18px;
				padding-bottom: 8px;

				@media (min-width: 1024px) {
					font-size: 20px;
				}

				a {
					color: var(--color-secondary);
				}

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 35px;
					height: 35px;
					border-radius: 50%;
					background-color: var(--color-secondary);

					&:before {
						display: inline-block;
						width: 25px;
						height: 15px;
						content: "";
						mask: url('/assets/icons/followUs.svg');
						mask-size: cover;
						background-color: #fff;
					}
				}
			}

			.images {
				display: flex;
				gap: 20px;
				justify-content: center;
				flex-wrap: wrap;
				height: fit-content;
				margin-top: 20px;

				@media (max-width: 1024px) {
					gap: 12px;
				}

				.image {
					overflow: hidden;
					width: 100%;
					max-width: 23%;
					border-radius: var(--border-radius-default);
					cursor: pointer;
					transition: transform .4s ease-in-out;

					@media (max-width: 1023px) {
						max-width: 45%;
					}

					img {
						width: 100%;
						height: 100%;
						aspect-ratio: 244/244;
						object-fit: cover;
					}

					&:hover {
						transform: scale(1.05);
					}
				}
			}
		}
	}
}
