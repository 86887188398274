@layer theme {
	.header__bg {
		height: auto;
		pointer-events: none;
		position: absolute;
		width: 100%;
		z-index: -1;

		.home:has(.block_bookingBlock:first-child .whiteText) & {
			display: none;
		}
	}

	header {
		--container-width: 2220px;
		--container-padding: 125px;

		@media (max-width: 1530px) {
			--container-padding: 50px;
		}
		@media (max-width: 1023px) {
			--container-padding: 20px;
		}
		.sticky-header & {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			background-color: #fef9f3;
			z-index: 10;
			transition: background .4s ease;
		}
		.home:has(.block_bookingBlock:first-child) & {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
		}

		.logo {
			display: flex;
			align-items: flex-end;
			width: 170px;
			height: 23px;
			order: 1;

			@media (min-width: 640px) {
				width: 200px;
				height: 26px;
			}
			@media (min-width: 1024px) {
				flex-direction: column;
				align-items: center;
				gap: 13px;
				width: 149px;
				height: 76px;
				order: inherit;
			}

			.icon {
				height: 21px;
				margin-right: 6px;

				@media (min-width: 640px) {
					height: 27px;
				}
				@media (min-width: 1024px) {
					margin: 0;
					height: 49px;
				}
			}
			.text {
				height: 11px;
				margin-bottom: 2px;
				transition: filter .4s ease;

				@media (min-width: 640px) {
					height: 14px;
				}
				.home:not(.sticky-header):has(.block_bookingBlock:first-child .whiteText) & {
					filter: brightness(0) invert(1);
				}
			}
		 }

		.bookButton {
			@media (max-width: 1023px) {
				order: 2;
				margin-left: auto;
				margin-right: 39px;
			}
			@media (max-width: 639px) {
				display: none;
			}

			button {
				min-width: 150px;
			}
		}

		.navToggle {
			padding: 5px 3px;
			background-color: transparent;
			z-index: 99;
			display: flex;
			flex-direction: column;
			gap: 5px;

			@media (min-width: 1024px) {
				display: none;
			}
			@media (max-width: 1023px) {
				order: 3;
			}

			span {
				display: block;
				width: 35px;
				height: 3px;
				background: var(--color-secondary);
				border-radius: 4px;
				transition: all .4s ease;

				body.nav-open & {
					&:first-child {
						transform: rotate(45deg) translate3d(5px, 5px, 0);
					}
					&:last-child {
						transform: rotate(-45deg) translate3d(6px, -6px, 0);
					}
				}
			}
			body.nav-open &,
			&:hover {
				span:nth-child(2) {
					background-color: var(--color-primary);
				}
			}
		}
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-block: 48px 5px;
		margin-bottom: 20px;
		color: var(--color-primary);
		transition: padding-top .3s ease;

		.sticky-header & {
			padding-top: 30px;
		}
	}

	.header-nav-links {
		font-size: 18px;

		@media (min-width: 1024px) {
			margin-block: auto;
		}
		@media (max-width: 1530px) and (min-width: 1024px) {
			font-size: 16px;
		}

		.wrapper {
			@media (min-width: 1024px) {
				display: flex;
				align-items: center;
				gap: 50px;
			}
			@media (max-width: 1530px) and (min-width: 1024px) {
				gap: 30px;
			}
		}

		a {
			font-weight: bold;
			text-transform: uppercase;

			.home:not(.sticky-header):has(.block_bookingBlock:first-child .whiteText) & {
				@media (min-width: 1024px) {
					color: #fff;
				}
			}
		}
	}

	/* Mobile menu */
	@media (max-width: 1023px) {
		.header-nav-links {
			position: fixed;
			inset: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fef9f3;
			transition: opacity .4s ease;
			background-image: url(/assets/backgrounds/Torfajokull-lg.webp);
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 98;

			body:not(.nav-open) & {
				opacity: 0;
				pointer-events: none;
			}
			body:not(.nav-open):not(.nav-closing) & {
				visibility: hidden;
			}

			.wrapper {
				display: flex;
				flex-direction: column;
				gap: 30px;

				&.animate a {
					opacity: 1;
					transform: translateY(0);
				}
			}

			a {
				display: flex;
				align-items: center;
				gap: 15px;
				opacity: 0;
				transform: translateY(20px);
				transition: opacity 0.3s ease, transform 0.3s ease;

				&:before {
					content: '';
					display: block;
					width: 20px;
					height: 2px;
					background: var(--color-secondary);
				}
			}
		}

		body.nav-open {
			overflow: hidden;

			main,
			footer {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
	@media (max-width: 640px) {
		.header-nav-links {
			background-image: url(/assets/backgrounds/Torfajokull-sm.webp);
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-o-transform: translateY(-30px)
	}

	to {
		opacity: 1;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0)
	}
}
