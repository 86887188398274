.caren__vehicle {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
	padding: 30px;
	border: 1px solid #f2f2f2;
	border-radius: 16px;
	background-color: #fff;
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.10);

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	@media (max-width: 768px) {
		padding: 20px;
	}

	&+h2 {
		font-weight: 500;
		margin-block: 80px 30px;
	}

	.mainWrapper {
		display: flex;
		align-items: center;
		gap: 80px;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 40px;
		}

		.image {
			display: flex;
			gap: 15px;
			flex-direction: column;

			&:not(:first-child) {
				margin-top: auto;
			}

			img {
				width: 100%;
				max-width: 350px;
				height: auto;
				aspect-ratio: 355/206;
				object-fit: contain;

				@media (max-width: 768px) {
					max-width: 100%;
				}
			}

			a.toggle {
				position: relative;
				width: fit-content;
				transition: color .3s ease;

				&:after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					content: "";
					display: block;
					background-color: var(--color-secondary);
					transition: width .3s ease 0s, left .3s ease 0s;
				}

				&:hover {
					&:after {
						left: 50%;
						width: 0;
					}
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			gap: 30px;

			h2 {
				font-size: 24px;
				font-weight: 500;

				span {
					font-size: 16px;
					font-weight: normal;
					color: #4f4f4f;
				}
			}

			ul.features {
				list-style: none;
				padding: 0;
				margin: 0;

				display: flex;
				column-gap: 2%;
				row-gap: 22px;
				flex-wrap: wrap;
			}

			li {
				position: relative;
				display: flex;
				gap: 20px;
				align-items: center;
				width: 48%;

				&:before {
					display: inline-block;
					width: 22px;
					height: 22px;
					content: "";
					border-radius: 50%;
					background-color: rgba(178, 104, 28, 0.10);
				}

				&:after {
					display: inline-block;
					width: 24px;
					height: 24px;
					content: "";
					background-size: cover;
					background-position: 50%;

					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					left: 5px;
				}

				&.transmission:after {
					background-image: url('/assets/icons/caren/caren-icon-transmission.svg');
				}

				&.passengers:after {
					background-image: url('/assets/icons/caren/caren-icon-passengers.svg');
				}

				&.doors:after {
					background-image: url('/assets/icons/caren/caren-icon-doors.svg');
				}

				&.fueltype:after {
					background-image: url('/assets/icons/caren/caren-icon-fuel.svg');
				}

				&.drive:after {
					background-image: url('/assets/icons/caren/caren-icon-drive.svg');
				}

				&.highland:after {
					background-image: url('/assets/icons/caren/caren-icon-highland.svg');
				}

				&.highlandNot:after {
					background-image: url('/assets/icons/caren/caren-icon-not-highland.svg');
				}

				&.beds:after {
					background-image: url('/assets/icons/caren/caren-icon-beds.svg');
				}
			}
		}
	}

	.priceWrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 10px;

		.toggle {
			margin-right: auto;
		}

		.price {
			font-size: 24px;
			font-weight: 500;
			color: #000;

			@media (max-width: 768px) {
				font-size: 20px;
			}

			span {
				font-size: 14px;
				font-weight: normal;
				margin-inline: 5px;
			}
		}
	}

	.details {
		display: flex;
		flex-direction: column;
		gap: 30px;
		line-height: 1.5;

		strong {
			font-weight: 500;
		}

		.list {
			margin-bottom: 20px;

			h2 {
				font-size: 20px;
				font-weight: 500;
				color: #000;
				padding-bottom: 20px;
				margin-bottom: 20px;
				border-bottom: 1px solid var(--color-secondary);
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0 0 30px;

				@media (min-width: 768px) {
					column-count: 2;
					column-gap: 30px;
				}
			}

			li {
				position: relative;
				padding-left: 20px;
				margin-bottom: 10px;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 9px;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: currentColor;
				}
			}
		}

		.images {
			display: grid;
			column-gap: 20px;
			row-gap: 30px;
			grid-template-columns: repeat(5, 1fr);

			@media (max-width: 768px) {
				row-gap: 20px;
				grid-template-columns: repeat(2, 1fr);
			}

			.image {
				img {
					width: 100%;
					aspect-ratio: 150/100;
					object-fit: cover;
					border-radius: var(--border-radius-default);
				}
			}
		}
	}

	&.card {
		position: relative;
		margin-bottom: 0;
		transition: border-color .3s ease;

		@media (max-width: 1024px) {
			max-width: 432px;
			margin-inline: auto;
		}

		&:hover {
			border: 1px solid var(--color-secondary);
		}

		.mainWrapper {
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
			gap: 20px;

			a {
				display: inline-block;
				margin-bottom: 10px;

				&:before {
					position: absolute;
					inset: 0;
					content: '';
					z-index: 1;
				}

				h3 {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 8px;

					font-size: 24px;
					font-weight: 500;
					transition: color .3s ease;

					span {
						font-size: 16px;
						font-weight: normal;
						color: #4f4f4f;
					}
				}

				&:hover {
					h3 {
						color: var(--color-secondary);
					}
				}
			}

			.info {
				width: 100%;
				align-items: center;

				.features {
					width: 100%;

					li {
						flex-direction: column;
						align-items: center;
						gap: 10px;
						width: unset;
						flex: 1;
						text-align: center;

						&:after {
							top: 0;
							transform: unset;
							left: 40%;
						}

						&:nth-child(n+5) {
							display: none;
						}
					}
				}

				.priceWrapper {
					margin-top: auto;
					align-items: flex-end;
					gap: 10px;
				}
			}
		}
	}

	/* Closed */
	&:not(.open) {
		a.toggle:before {
			content: attr(data-toggle-open);
		}

		.details {
			display: none;
		}
	}

	/* Open */
	&.open {
		a.toggle:before {
			content: attr(data-toggle-close);
		}
	}
}
