@layer base, theme, components, utilities;

@import url(base.css) layer(base);
@import url(theme.css) layer(theme);
@import url(animation.css) layer(theme);
@import url(components.css) layer(components);
@import url(utilities.css) layer(utilities);
@import url(../../templates/layout/header/header.css);
@import url(../../templates/layout/footer/footer.css);
@import url(../../templates/components/button/button.css);
@import url(../../templates/components/card/v1/card.v1.css);
@import url(../../templates/components/hero/hero.css);
@import url(../../templates/contentBlocks/bookingBlock/bookingBlock.css);
@import url(../../templates/contentBlocks/contentWithImage/contentWithImage.css);
@import url(../../templates/contentBlocks/imageGallery/imageGallery.css);
@import url(../../templates/contentBlocks/latestBlog/latestBlog.css);
@import url(../../templates/contentBlocks/richTextBlock/richTextBlock.css);

@import url(../../templates/caren/caren.css) layer(components);
@import url(../../templates/caren/components/actionCard/actionCard.css) layer(components);
@import url(../../templates/caren/components/booking/booking.css) layer(components);
@import url(../../templates/caren/components/personalInfo/personalInfo.css) layer(components);
@import url(../../templates/caren/components/stepper/stepper.css) layer(components);
@import url(../../templates/caren/components/terms/terms.css) layer(components);
@import url(../../templates/caren/components/vehicle/vehicle.css) layer(components);
@import url(../../templates/caren/components/vehicle/fleet.css) layer(components);
@import url(../../templates/caren/components/vehicleSidebar/vehicleSidebar.css) layer(components);


/*
@layer base {
	Normalize browser defaults
}

@layer theme {
	project specific styles like variables
}

@layer components {
	Styles for common components like buttons and cards
}

@layer utilities {
	Reusable low-level styles like colors and spacing
}
*/
