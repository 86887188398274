@layer components {
	.card__block.v1 {
		position: relative;
		max-width: 480px;

		&:hover {
			--readMoreLineWidth: calc(92px + 30px);
		}

		.image {
			margin-bottom: 25px;

			img {
				width: 100%;
				aspect-ratio: 470/314;
				object-fit: cover;
				border-radius: var(--border-radius-default);
			}
		}

		.headline {
			font-size: 14px;
			font-weight: bold;
			color: var(--color-secondary);
			padding-block: 7px;
		}

		.title {
			font-size: 24px;
			line-height: calc(42 / 24);
			padding-block: 6px;

			a {
				color: var(--color-primary);

				&:before {
					content: '';
					position: absolute;
					inset: 0;
				}
			}
		}

		.intro {
			font-size: 18px;
			line-height: calc(28 / 18);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.readMore {
			display: flex;
			align-items: center;
			gap: 22px;
			width: fit-content;
			margin-top: 20px;
			font-weight: bold;
			text-transform: uppercase;
			color: var(--color-secondary);

			&:before {
				content: '';
				width: var(--readMoreLineWidth, 92px);
				height: 2px;
				background: currentColor;
				transition: width .5s ease;
			}
		}

		&.horizontal {
			@media (min-width: 960px) {
				display: flex;
				flex-direction: row;
				max-width: 960px;
				gap: 50px;
				align-items: center;

				> div {
					flex: 1;
					margin: 0;
				}
			}
		}
	}
}
