.caren__terms {
	padding: 30px;
	border: 1px solid #f2f2f2;
	border-radius: var(--border-radius-default);
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 40px;

	.wrapper {
		font-size: 18px;
		line-height: calc(26/18);
		color: #000;
		max-height: 470px;
		overflow: auto;
		padding-right: 20px;
		margin-bottom: 30px;

		&::-webkit-scrollbar {
			width: 10px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			border-radius: 28px;
			background: rgba(230, 157, 46, .2);
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: var(--color-secondary);
		}
	}
}

.formElm {
	&.checkbox {
		display: flex;
		gap: 16px;

		input[type=checkbox] {
			position: absolute;
			opacity: 0;
			pointer-events: none;

			&:checked + label:before {
				content: '✔';
				font-size: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		label {
			display: inline-flex;
			align-items: center;
			font-size: 18px;
			font-weight: 500;
			text-transform: uppercase;
			color: #000;
			cursor: pointer;

			&:before {
				content: '';
				display: inline-block;
				width: 20px;
				height: 20px;
				border: 2px solid #333;
				border-radius: 4px;
				margin-right: 8px;
				background-color: #fff;
				transition: background-color 0.3s, border-color 0.3s;
			}
		}
	}
}
