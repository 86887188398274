@layer components {
	.block_hero {
		.contentWrap {
			@media (min-width: 1024px) {
				padding-right: 15px;
			}
		}
	}
	.hero__container {
		height: fit-content;
		max-width: 1690px;
		margin-left: auto;
		overflow: hidden;

		.image {
			animation-name: fadeInLeft;
			animation-fill-mode: both;
			animation-duration: 1.2s;
			padding-bottom: 40px;

			img {
				width: 100%;
				border-radius: var(--border-radius-default);
			}
		}

		.label {
			font-weight: bold;
			color: var(--color-secondary);

			@media (min-width: 768px) {
				font-size: 20px;
			}
			@media (min-width: 1536px) {
				font-size: 22px;
			}
		}

		h1 {
			font-size: 75px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: calc(88/75);
			padding-block: 20px;

			@media (max-width: 1530px) {
				font-size: 50px;
			}

			@media (max-width: 1024px) {
				font-size: 40px;
			}
		}

		.content {
			font-size: 20px;
			font-weight: 300;
			line-height: 1.5;
			animation-name: fadeInUp;
			animation-fill-mode: both;
			animation-duration: 1.2s;

			p {
				margin-bottom: 35px;

				&:first-of-type {
					margin-top: 5px;
				}
			}

			button {
				padding-block: 21px;

				@media (min-width: 1024px) {
					min-width: 200px;
				}
			}
		}
	}

	@media (min-width: 1024px) {
		.hero__container {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;

			.image,
			.content {
				width: 50%;
			}

			.image {
				padding: 0;
			}

			.content {
				margin-right: 80px;
			}
		}
	}
}
