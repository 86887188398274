.fleet__overview {
	max-width: 1344px;
	margin-inline: auto;

	h2 {
		font-size: 40px;
		font-weight: 500;
		text-align: center;
		margin-bottom: 80px;
	}

	.cards {
		display: grid;
		grid-gap: 30px;

		@media (min-width: 480px) {
			grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
		}
	}

	.buttonWrapper {
		margin-block: 40px;
		text-align: center;

		a {
			display: inline-block;
			text-transform: none;
			padding-block: 19px;
		}
	}
}
