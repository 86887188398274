footer {
	form {
		margin: 0 0 0 0;
	}

	input[type="email"] {
		appearance: none;
		transform: scale(1);
		transition: border-color 0.2s ease, background-color 0.2s ease;
		background-color: transparent;
		border-radius: var(--border-radius-default);
		border: solid 1px #ffff;
		color: #ffff;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

	input[type="email"]:invalid {
		box-shadow: none;
	}

	input[type="email"]:focus {
		background-color: rgba(255, 255, 255, 0.125);
		border-color: #e69d2e;
	}

	input[type="email"] {
		height: 2.75em;
	}

	::-webkit-input-placeholder {
		color: #dad7d7;
		opacity: 1;
	}

	/* Button */
	input[type="submit"],
	button,
	.button {
		appearance: none;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
		opacity 0.2s ease-in-out;
		background-color: #5a4a42;
		color: #ffffff !important;

		cursor: pointer;
		display: inline-block;

		height: 2.75em;
		line-height: 2.75em;

		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}

	input[type="submit"]:hover,
	button:hover,
	.button:hover {
		text-decoration-line: underline;
		text-underline-offset: 0.2em;
	}

	input[type="submit"]:active,
	button:active,
	.button:active {
		background-color: #302722;
	}

	input[type="submit"].disabled,
	input[type="submit"]:disabled,
	button.disabled,
	button:disabled,
	.button.disabled,
	.button:disabled {
		opacity: 0.5;
	}

	@media screen and (max-width: 480px) {
		input[type="submit"],
		button,
		.button {
			padding: 0;
			width: 100%;
		}
	}

	/* Signup Form */

	#mc_embed_signup_scroll,
	#signup-form {
		display: flex;
		gap: 0.75em;
		position: relative;
	}

	#signup-form input[type="email"],
	#mc_embed_signup_scroll input[type="email"] {
		width: 12em;
	}

	#mc_embed_signup_scroll > *,
	#signup-form > * {
		margin: 0 0 0 0;
	}

	#mc_embed_signup_scroll > :first-child,
	#signup-form > :first-child {
		margin: 0 0 0 0;
	}

	@media screen and (max-width: 480px) {
		#mc_embed_signup_scroll,
		#signup-form {
			flex-direction: column;
			gap: 0;
		}

		#signup-form input[type="email"],
		#mc_embed_signup_scroll input[type="email"] {
			width: 100%;
		}

		#mc_embed_signup_scroll > *,
		#signup-form > * {
			margin: 0.5em 0 0 0;
		}
	}
}
