.vehicle__sidebar {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	padding: 30px;
	border: 1px solid #f2f2f2;
	border-radius: var(--border-radius-default);
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);

	@media (max-width: 768px) {
		padding: 20px;
	}

	.titleWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 22px;
			font-weight: 500;
			color: #000;
		}

		a.change {
			position: relative;
			width: fit-content;
			color: var(--color-secondary);

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				content: "";
				display: block;
				background-color: var(--color-secondary);
				transition: width .3s ease 0s, left .3s ease 0s;
			}

			&:hover {
				&:after {
					left: 50%;
					width: 0;
				}
			}
		}
	}

	.image {
		img {
			width: 100%;
			max-width: 370px;
			height: auto;
			aspect-ratio: calc(338/201);
			object-fit: contain;
		}
	}

	.title {
		h2 {
			font-size: 24px;
			font-weight: 500;

			span {
				font-size: 16px;
				font-weight: normal;
				color: #4f4f4f;
			}
		}
	}

	.priceTable {
		color: #000;
		padding: 25px 15px;
		border-radius: var(--border-radius-default);
		background-color: #f0eeea;

		table, tbody, th, td {
			display: block;
		}

		tr {
			display: flex;
			justify-content: space-between;
			gap: 10px;
		}
		tbody {
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}
		th {
			text-align: left;
			font-weight: 500;
		}
		td {
			text-align: right;
			font-weight: 500;
		}
		th,
		td {
			padding-block: 5px;
		}

		.basePrice {
			th {
				font-weight: normal;
			}
			td {
				font-weight: 500;
			}
		}

		.extras,
		.insurance {
			tr:not(.title) th {
				font-weight: normal;
				padding-left: 16px;
			}
		}

		[data-price="Inc."] {
			color: var(--color-secondary);
		}
	}

	.totalPrice {
		display: flex;
		flex-direction: column;
		gap: 20px;
		font-size: 20px;
		font-weight: 500;
		color: var(--color-primary);
		text-align: right;
		padding: 25px 15px;
		border-radius: var(--border-radius-default);
		background-color: var(--color-secondary);

		span,
		.small {
			font-size: 14px;
			font-weight: normal;
		}
	}

	.locations {
		display: flex;
		flex-direction: column;
		gap: 20px;
		color: #000;
		padding: 25px 15px;
		border-radius: var(--border-radius-default);
		background-color: #f0eeea;

		.group {
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 16px;

			.bold {
				font-weight: 500;
				color: var(--color-secondary);
			}
		}
	}
}
