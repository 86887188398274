.caren__booking {
	--arrow: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5001 8.35223L0.0478516 1.89998L1.9001 0.0477295L6.5001 4.64773L11.1001 0.0477295L12.9524 1.89998L6.5001 8.35223Z' fill='%23E69D2E'/%3E%3C/svg%3E");
	width: 100%;
	max-width: 1344px;
	border-radius: var(--border-radius-default);;
	border-bottom: 3px solid var(--color-secondary);
	background: rgba(255, 255, 255, 0.90);
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.1);
	padding: 20px;
	container-name: booking;
	container-type: inline-size;
	text-align: left;
	z-index: 2;

	.booking__block & {
		border: 0;
	}
	.sidebar & {
		--border-radius-default: 16px;
	}

	@media (min-width: 1200px) {
		padding-inline: 40px;
	}

	form {
		display: flex;
		gap: 24px;

		@container booking (width > 768px) {
			align-items: center;
			justify-content: center;
		}
		@container booking (width < 900px) {
			gap: 15px;
		}
		@container booking (width < 768px) {
			flex-wrap: wrap;
			gap: 0;
		}
	}

	.elm {
		margin-bottom: 6px;
		@container booking (width > 768px) {

		}

		@container booking (width < 992px) {
			width: 100%;
		}

		&.time {
			@container booking (width > 768px) {
				min-width: 80px;
			}
		}
		&.dates {
			@container booking (width > 768px) {
				min-width: 220px;
			}
		}

		&.dropoffDate {
			pointer-events: none;
		}

		@container booking (width < 992px) and (width > 768px) {
			&.location select {
				width: 72px;
			}
		}

		/*
		@container booking (width < 992px) and (width > 768px) {
			&.location {
				width: 40%;
				padding-right: 30px;
			}

			&.date {
				width: 40%;
				padding-right: 30px;
			}

			&.time {
				width: 20%;
			}
		}

		 */

		@container booking (width < 768px) {
			&.date {
				width: 70%;
				padding-right: 30px;

				&+.time {
					width: 30%;
				}
			}
		}
	}

	label {
		display: inline-block;
		padding: 4px 0;
		font-size: 16px;
		font-weight: 500;
		color: #000;

		@container booking (width > 768px) {
			margin-bottom: 10px;
		}
	}

	.lmnt {
		display: block;
		width: 100%;
	}

	select,
	input[type=text] {
		width: 100%;
		border: 0;
		box-shadow: none;
		padding: 5px 40px 5px 0;
		background-color: transparent;
		background-image: var(--arrow);
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 12px;
		font-size: 16px;
		color: #333;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.seperator {
		@container booking (width > 768px) {
			border-left: 1px solid #5A4A42;
			margin-inline: 7px;
			min-height: 50px;
		}
		@container booking (width < 768px) {
			width: 100%;
			border-top: 1px solid #5A4A42;
			margin-block: 10px;
		}
	}

	.controls {
		@container booking (width < 768px) {
			margin-top: 15px;
		}
	}

	.button-container {
		padding-inline: 26px;
		width: auto;
		border-radius: var(--border-radius-default);
	}

	.sidebar & {
		.dates label,
		.location label {
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
	}
}
