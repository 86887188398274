.caren__stepper {
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		font-size: 16px;
		font-weight: 500;

		@media (max-width: 992px) {
			flex-direction: column;
			gap: 5px;
		}
	}

	li {
		position: relative;
		counter-increment: item;
		min-height: 41px;
		padding-left: 54px;
		display: flex;
		align-items: center;
		margin-right: 30px;

		&:before,
		&:after {
			position: absolute;
			left: 0;
			top: 0;
		}

		&:before {
			content: counter(item);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 41px;
			aspect-ratio: 1;
			border: 1px solid var(--color-secondary);
			border-radius: 50%;
		}

		&.checked,
		&.current {
			&:before {
				background: var(--color-secondary);
			}
		}

		&.checked {
			color: var(--color-secondary);

			&:before {
				content: '';
			}
			&:after {
				content: '';
				display: block;
				width: 41px;
				height: 41px;
				mask: url('/assets/icons/caren/caren-icon-check.svg') no-repeat center center;
				mask-size: 22px;
				background-color: #5A4A42;
			}
		}

		a {
			color: var(--color-primary);

			&:hover {
				color: var(--color-secondary);
			}
		}
	}
}
