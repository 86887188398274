@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		pointer-events: none;
	}
}
