@layer components {
	.content__image {
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		max-width: 1460px;

		.image {
			overflow: hidden;
			width: 100%;
			max-width: 700px;

			@media (max-width: 1023px) {
				max-width: 100%;
			}

			img {
				width: 100%;
				object-fit: cover;
				height: 300px;
				border-radius: var(--border-radius-default);
				@media (min-width: 1024px) {
					height: 420px;
				}
			}
		}

		.content {
			width: fit-content;
			max-width: 50%;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.5;

			h2 {
				font-size: 24px;
				font-weight: 700;
				text-transform: uppercase;
				line-height: normal;
				padding-bottom: 15px;
			}

			ul {
				list-style-type: disc;
				padding: 0;
				margin: 0 0 0 20px;
			}
		}

		&.left {
			flex-direction: row;

			@media (max-width: 1024px) {
				flex-direction: column;
			}

			.content {
				padding-left: 100px;

				@media (max-width: 1024px) {
					max-width: 100%;
					padding-left: 0;
				}
			}
		}

		&.right {
			flex-direction: row-reverse;

			@media (max-width: 1024px) {
				flex-direction: column;
			}

			.content {
				padding-right: 100px;

				@media (max-width: 1024px) {
					max-width: 100%;
					padding-right: 0;
				}
			}
		}
	}
}
