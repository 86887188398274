@layer components {
	.button-container {
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 16px 25px;
		border-radius: var(--border-radius-default);
		transition: all .3s ease;
	}

	.button-primary {
		color: var(--color-primary);
		border: 1px solid var(--color-secondary);
		background-color: var(--color-secondary);

		&:hover {
			color: var(--color-secondary);
			background-color: var(--color-primary);
		}
	}

	.button-secondary {
		background-color: var(--color-primary);
		color: var(--color-secondary)
	}

	.button-info {
		text-align: left;

		.label,
		.value {
			display: block;
		}
	}

	button.arrow span {
		display: inline-flex;
		align-items: center;
		gap: 10px;

		&:after {
			display: inline-block;
			width: 33px;
			height: 32px;
			content: "";
			mask: url('/assets/icons/icon-button-arrow.svg');
			mask-size: cover;
			background-color: currentColor;
			transition: background-color .3s ease;
		}
	}

	.button-outlined {

	}

	.button-filled {

	}

	.caren__buttons {
		display: flex;
		gap: 20px;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		button {
			all: unset;
			display: flex;
			gap: 40px;
			justify-content: space-between;
			align-items: center;
			padding: 20px 30px;
			border: 1px solid var(--color-secondary);
			border-radius: var(--border-radius-default);
			background-color: #fff;
			cursor: pointer;
			transition: background-color .3s ease, border-color .3s ease;

			.button-inner {
				display: flex;
				flex-direction: column;
				gap: 10px;

				> span {
					transition: color .3s ease;
				}

				.label {
					font-size: 18px;
					color: var(--color-primary);
					font-weight: bold;
				}

				.charged,
				.description {
					font-size: 14px;
				}

				.value {
					font-size: 22px;
					font-weight: 500;
					color: var(--color-secondary);

					span {
						font-size: 18px;
						font-weight: normal;
						margin-right: 5px;
					}
				}
			}

			&:after {
				display: inline-block;
				width: 33px;
				height: 32px;
				content: "";
				mask: url('/assets/icons/icon-button-arrow.svg');
				mask-size: cover;
				background-color: var(--color-secondary);
				transition: background-color .3s ease;
			}

			&.button-filled {
				background-color: var(--color-secondary);
			}

			&.button-filled,
			&:hover {
				.button-inner {
					> span {
						color: #fff;
					}
				}

				&:after {
					background-color: #fff;
				}
			}

			&:hover {
				background-color: var(--color-primary);
				border-color: var(--color-primary);
			}
		}
	}
}
