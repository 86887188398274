@layer components {
	.rich__text__block {
		line-height: 26px;
		background-color: transparent;
		margin: 0 auto;
		border-radius: var(--border-radius-default);
		max-width: 1200px;
		@media (min-width: 1024px) {
			padding-top: 40px;
			padding-left: 100px;
			padding-right: 100px;
			background-color: var(--body-background-color);

		}

		.title {
			font-size: 46px;
			padding-bottom: 40px;
		}

		.label {
			color: var(--color-secondary);
			font-weight: 700;
		}

	}

}
